/*

  Router

    using ui-router

*/
;(function () {
  angular
    .module('app.core')
    .config(Router);

  function Router($urlRouterProvider, $stateProvider){

    // $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('error', {
        templateUrl: 'modules/error/error.controller.html',
        controller: 'ErrorController',
        controllerAs: 'vm',
      });

  }
})();