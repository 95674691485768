(function() {
  'use strict';

  angular
    .module('adviser')
    .controller('VideoController', VideoController);

  VideoController.$inject = ['_', '$scope', '$q', '$http', '$timeout', 'YouTubeAPI', 'Questions', 'Answers', 'constants'];
  function VideoController(_, $scope, $q, $http, $timeout, API, Questions, Answers, constants) {
    var vm = this;

    vm.categories = [
      {name: 'Category 1', slug: 'category-1', start: 116, end: 174},
      {name: 'Category 2', slug: 'category-2', start: 174, end: 247},
      {name: 'Category 3', slug: 'category-3', start: 247, end: 276},
      {name: 'Category 4', slug: 'category-4', start: 276, end: 301},
      {name: 'Category 5', slug: 'category-5', start: 301, end: 335}
    ];

    vm.collapseOnVideoEnd = collapseOnVideoEnd;
    vm.checkAnswer = checkAnswer;
    vm.playSection = playSection;
    vm.skipBack = skipBack;
    vm.parseIncorrect = parseIncorrect;
    vm.learnVid = constants.learnVid;


    vm.incorrect = [];

    $scope.$on('$destroy', function() {
      API.events.onStateChange = null;
    });

    function collapseOnVideoEnd() {
      API.events.onStateChange = function(evt) {
        if (evt.data === 0) {
          // Video ended
          $timeout(function() { 
            vm.hideVideo = true;
          });
          
        }
      };
    }

    function checkAnswer(ans, key) {
      if (typeof ans === 'string') {
        return ans === key;
      } else {
        if (ans.length !== key.length) { return false; }
        else {
          return _.filter(ans, function(o) {
            return key.indexOf(o) === -1;
          }).length < 1;
        }
      }
    }

    function playSection(id, start, end) {
      API.playSegment(id, start, end).then(function(player) {
        vm.showVideo = true;
      }).catch(function(err) {
        return;
      });
    }

    function skipBack(id, time) {
      var player = API.players[id];
      var t = time || 15;
      var jumpTime = player.getCurrentTime() - t;
      jumpTime = jumpTime <= 0 ? 0 : jumpTime;

      player.seekTo(jumpTime);
    }

    function parseIncorrect() {
      $http.get('api/users/authenticate', {})
        .success(function(user){
          $q.all({
            questions: Questions.load('prepare'),
            answers: Answers.query({userkey: user.userkey}).$promise
          }).then(function(response) {

            var answers = _.indexBy(_.filter(response.answers, function(item) {
              return item.bin && item.category === 'prepare';
              // category: 'prepare'
            }), 'variable');

            var incorrect = [];

            _.each(response.questions, function(qstn) {
              if (qstn.hasAnswer) {
                _.each(qstn.questions, function(q) {
                  var ans = answers[q.id];
                  if (ans && !vm.checkAnswer(ans.value, q.a)) {
                    incorrect.push('category-' + ans.bin);
                  }
                });
              }
            });

            $timeout(function() {
              vm.incorrect = incorrect;
            });

          });

        })
        .error(function(err) {
        });
      
    }
  }

})();