/*

  App Controller

    body-level controller, use for totally global functionality

*/
;(function () {
  'use strict';

  angular
    .module('app.core')
    .controller('AppController', AppController);

  function AppController($scope, $state, $location, globals, features) {
    var app = this;

    app.$state = $state;
    app.globals = $scope.globals = globals;

    if( window.location.host === 'localhost' || window.location.host === '205.186.156.50' ) {
      globals.debug = true;
    }

    $scope.$on('$stateChangeSuccess', function(){
      app.globals.bodyClass = null;
    });

  }


})();
