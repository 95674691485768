/**
 *
 * Progress Gauge
 *
 * Creates the "out of" number for the progress page.
 *
 */

(function() {
  'use strict';

  angular
    .module('adviser')
    .directive('exploreGraph', exploreGraph);

  exploreGraph.$inject = ['$stateParams'];
  function exploreGraph($stateParams) {
    var directive = {
      restirce: 'EA',
      templateUrl: function() {
        if ($stateParams.view === '6') {
          return 'modules/adviser/views/explore/explore-graph-mid.directive.html';
        } else {
          return 'modules/adviser/views/explore/explore-graph.directive.html';
        }
      },
      scope: {
        user: '=',
        parser: '@'
        // skip: '='
      },
      controller: GraphController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;
  }

  GraphController.$inject = ['_', '$q', 'Answers'];
  function GraphController(_, $q, Answers) {
    var vm = this;
    vm.results = [];

    activate();

    function isNumber (o) {
      return ! isNaN (o-0) && o !== null && o !== '' && o !== false;
    }

    var parseMap = {
      mid: function(answers) {
        // return $q(function(resolve, reject) {
          var results = [];

          for (var i=1; i<=5; i++) {
            var ans = _.filter(_.where(answers, {bin: '' + i}), function(obj) {
              return isNumber(obj.value);
            });

            var sum = _.reduce(ans, function(memo, obj) {
              var n = ((parseInt(obj.value, 10) - 1) / 4) * 100;
              return memo + n;
            }, 0);

            results[i-1] = {
              id: i,
              variable: '' + i,
              percent: Math.round(sum / (ans.length))
            };
          }

          // resolve(results)
          return results;
        // });
      },
      def: function(answers) {
        // return $q(function(resolve, reject) {
          var results = [];
          for (var i=1; i<=5; i++) {
            results[i-1] = _.find(answers, {variable: 'PREBN' + i});
            results[i-1].percent = (results[i-1].value - 1)/4*100;
          }

          // resolve(results);
          return results;
        // });
      }
    };

    function activate() {
      // var results = [];
      Answers.query({userkey: vm.user.userkey}, function(response) {
        if (response) {

          vm.results = parseMap[vm.parser || 'def'](response) //.then(function(results){

             // results;

            var prefs = [];

            for (var n=0; n<vm.results.length; n++) {
              var item = vm.results[n];
              if (item.percent >= 60) {
                prefs.push(item.variable.slice(-1));
              }
            }

            if (prefs.length) {
              vm.prefs = prefs.slice(0, -1).join(', ') + (prefs.length > 1 ? ' and ' : '') + prefs[prefs.length-1];
            }
          // });

        }
      });
    }
    
  }

})();