/*

  Configure the Adviser routes

*/
(function () {
  'use strict';

  angular
    .module('adviser')
    .config(Router);

  function Router($urlRouterProvider, $stateProvider){

    $urlRouterProvider.otherwise('/progress');

    $stateProvider
      .state('adviser', {
        abstract: true,
        templateUrl: 'modules/adviser/views/adviser.html',
        resolve: {
          user: UserAuthorize,
          configResolve: GetConfig
          // youTube: ['user', '$q', '$window', YouTubeInit]
        }
      })
      .state('adviser.progress', {
        url: '/progress',
        templateUrl: 'modules/adviser/views/progress.html',
        controller: 'ProgressController',
        controllerAs: 'vm',
        resolve: {
          userResolve: function(user) {
            return user;
          },
          progressResolve: UserProgress
        }
      })
      .state('adviser.welcome', {
        url: '/welcome',
        templateUrl: 'modules/adviser/views/welcome.html',
        controller: 'WelcomeController',
        controllerAs: 'vm',
        resolve: {
          userResolve: function(user) {
            return user;
          },
          progressResolve: WelcomeProgress
        }
      })
      .state('adviser.questions', {
        url: '/:step/:view',
        template: '<ng-include src="vm.questionTemplate"></ng-include>',
        controller: 'AdviserController',
        controllerAs: 'vm',
        resolve: {
          userResolve: function(user, configResolve, $stateParams, $state) {
            // Prevent access to all sections but reflect for control group users.
            var mockAccess = ['learn', 'explore', 'prepare', 'decide'];
            if (configResolve.environment === 'mock' && mockAccess.indexOf($stateParams.step) === -1) {
              $state.go('adviser.progress');
            } else if (user.role === 'control' && $stateParams.step !== 'reflect') {
              $state.go('adviser.progress');
            } else {
              return user;
            }
          },
          progressResolve: UserProgress,
          questionsResolve: function(user, $stateParams, Questions) {
            var template = null;

            /**
             * If the user is in the control group pull the questions from
             * a separate data set.
             */
            if (user.role === 'control' && $stateParams.step === 'reflect') { 
              template = 'reflect-control';
            }

            return Questions.load($stateParams.step, template);
          }
          // answersResolve: AdviserAnswers
        }
      });


    UserAuthorize.$inject = ['$http', '$state', '$q'];
    function UserAuthorize($http, $state, $q) {

      return $q(function(resolve, reject) {
        $http.get('api/users/authenticate', {})
          .success(function(response){
            resolve(response);
          })
          .error(function(err) {
            $state.go('auth.login');
          });
      });
    }

    GetConfig.$inject = ['$http', '$q'];
    function GetConfig($http, $q) {
      return $q(function(resolve, reject) {
        $http.get('api/adviser/config').success(function(response) {
          resolve(response);
        });
      });
    }

    UserProgress.$inject = ['$state', '$stateParams', 'Progress', 'ProgressData', 'userResolve'];
    function UserProgress($state, $stateParams, Progress, ProgressData, userResolve) {
      var user = userResolve;

      // If it's a control group user limit access to the reflect section.
      return Progress.get({ field: 'progress', userkey: user.userkey },
        function(response) {
          // If there is no progress, redirect to 
          if (!response.userkey) { $state.go('adviser.welcome'); }
          ProgressData.current = response;
        }, function(response) {
          $state.go('auth.login');
        }).$promise;
      
    }

    // AdviserAnswers.$inject = ['$q', 'Answers', 'userResolve'];
    // function AdviserAnswers($q, Answers, userResolve) {
    //   return $q(function(resolve, reject) {
    //     Answers.query({userkey: userResolve.userkey}, function(response) {
    //       if (response) {
    //         resolve(_.indexBy(response, 'variable'));
    //       } else {
    //         resolve({});
    //       }
          
    //     });
    //   });
    // }

    WelcomeProgress.$inject = ['$state', 'Progress', 'ProgressData', 'userResolve'];
    function WelcomeProgress($state, Progress, ProgressData, userResolve) {
      var user = userResolve;
      return Progress.get({ userkey: user.userkey }, 
        function(response) {
          // If there is already progress, redirect to progress screen.
          if (response.userkey) { $state.go('adviser.progress'); }
          ProgressData.current = response;
        }, function(response) {
        }).$promise;
    }

    YouTubeInit.$inject = ['user', '$q', '$window'];
    function YouTubeInit(user, $q, $window) {
      return $q(function(resolve, reject) {
        if ($window.YT) {
          resolve($window.YT);
        } else {
          var tag = document.createElement('script');

          tag.src = 'https://www.youtube.com/iframe_api';
          var firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          $window.onYouTubeIframeAPIReady = function() {
            resolve($window.YT);
          }
        }
      });
    }
  }
})();