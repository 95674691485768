(function() {

  'use strict';

  angular
    .module('admin')
    .controller('ResultsController', ResultsController);

  ResultsController.$inject = ['_', '$http', '$state', 'Questions', 'auth'];
  function ResultsController(_, $http, $state, Questions, auth) {

    var vm = this;

    vm.$state = $state;
    vm.auth = auth;
    vm.userKey = null;
    vm.categories = Questions.categories;

    vm.getResults = getResults;
    vm.activate = activate;

    vm.activate();

    function activate() {
      var userKey = $state.params.userKey;

      if (userKey) {
        getResults(userKey);
      }
    }

    function getResults(userKey) {

      vm.error = vm.success = null;

      vm.answers = null;
      _.each(vm.categories, function(ans) { ans.answers = []; });

      $http.get('api/adviser/answers/' + userKey, {}).success(function(response) {
        vm.answers = response;

        _.each(response, function(ans) {
          var idx = _.findIndex(vm.categories, {id: ans.category});
          // vm.categories[idx].answers = vm.categories[idx].answers || [];
          vm.categories[idx].answers.push(ans);
        });

      }).error(function(response) {

        if (response.error.status === 403) {
          $state.go('login');
        } else {
          vm.error = response.error.message;
        }
        
      });

    }

  }
})();