(function() {

  'use strict';

  angular
    .module('adviser')
    .controller('SummaryController', SummaryController);

  SummaryController.$inject = ['_', '$scope', '$state', 'Answers']
  function SummaryController(_, $scope, $state, Answers) {

    var vm = $scope.vm;
    var sum = this;


    activate();

    function activate() {
      Answers.query({userkey: vm.user.userkey}, function(response) {
        var keys = {
          decide: ['BN1SEL', 'BN2SEL', 'BN3SEL', 'BN4SEL', 'BN5SEL'],
          prepare: ['SURENU', 'SURCLR', 'SURSUP', 'SURBST'],
          redecide: ['BN1SEL2', 'BN2SEL2', 'BN3SEL2', 'BN4SEL2', 'BN5SEL2']
        };

        var values = {
          decide: ['No', 'Don\'t Know', 'Yes'],
          prepare: ['No', 'Don\'t Know'],
          redecide: ['No', 'Don\'t Know', 'Yes']
        };

        var answers = _.filter(response, function(ans) {
          if ($state.params.step === 'redecide' && $state.params.view === '0') {

            return _.indexOf(keys['decide'], ans.variable) !== -1; 
          }else{
            return _.indexOf(keys[$state.params.step], ans.variable) !== -1; // && _.indexOf(values, ans.value) !== -1;
          }
        });

        sum.answers = _.indexBy(answers, 'variable');

        sum.noAnswers = _.isEmpty(_.filter(answers, function(ans) {
          return _.indexOf(values[$state.params.step], ans.value) !== -1;
        }));

      });
    }

  }
})();