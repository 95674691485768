/*

  Router

    using ui-router

*/
(function () {
  angular
    .module('admin')
    .config(Router);

  function Router($urlRouterProvider, $stateProvider){

    // $urlRouterProvider.otherwise('/admin');

    $stateProvider
      .state('admin', {
        url: '/admin',
        templateUrl: 'modules/admin/views/dashboard.html',
        controller: 'AdminController',
        controllerAs: 'vm',
        resolve: {
          auth: Authorize
        }
      })
      .state('admin.home', {
        url: '/home',
        templateUrl: 'modules/admin/views/home.html'
      })
      .state('admin.add', {
        url: '/add',
        templateUrl: 'modules/admin/views/user-add.html',
        controller: 'AdminController',
        controllerAs: 'vm',
        resolve: {
          superadmin: CheckSuperadmin
        }
      })
      .state('admin.results', {
        url: '/results',
        templateUrl: 'modules/admin/views/user-results.html',
        controller: 'AdminController',
        controllerAs: 'vm'
      })
      .state('admin.results.user', {
        url: '/:userKey',
        templateUrl: 'modules/admin/views/user-results-table.html',
        controller: 'ResultsController',
        controllerAs: 'vm'
      })
      .state('admin.progress', {
        url: '/progress',
        templateUrl: 'modules/admin/views/progress.html',
        resolve: {
          superadmin: CheckSuperadmin,
          progressResolve: AllProgress
        }
      })
      .state('admin.progress.all', {
        url: '/all',
        templateUrl: 'modules/admin/views/progress-table.html',
        controller: 'AdminProgressController',
        controllerAs: 'vm'
      })
      .state('admin.progress.active', {
        url: '/active',
        templateUrl: 'modules/admin/views/progress-table.html',
        controller: 'AdminProgressController',
        controllerAs: 'vm'
      })
      .state('admin.progress.complete', {
        url: '/complete',
        templateUrl: 'modules/admin/views/progress-table.html',
        controller: 'AdminProgressController',
        controllerAs: 'vm'
      })
      .state('login', {
        url: '/admin/login',
        templateUrl: 'modules/admin/views/login.html',
        controller: 'AuthenticationController',
        controllerAs: 'vm',
        resolve: {
          auth: AuthorizeLogin
        }
      });


    Authorize.$inject = ['$http', '$state', '$q'];
    function Authorize($http, $state, $q) {

      return $q(function(resolve, reject) {
        $http.get('api/admin/authenticate', {})
          .success(function(response){
            resolve(response);
          })
          .error(function(err) {
            $state.go('login');
          });
      });
    }

    AuthorizeLogin.$inject = ['$http', '$state'];
    function AuthorizeLogin($http, $state) {
      return $http.get('api/admin/current', {}).success(function(response) {
        if (response.role === 'admin' || response.role === 'superadmin') {
          $state.go('admin.home');
        }
      }).error(function(err) {});

    }

    CheckSuperadmin.$inject = ['$state', 'auth'];
    function CheckSuperadmin($state, auth) {
      if (auth.role !== 'superadmin') {
        $state.go('admin.home');
      }
    }

    AllProgress.$inject = ['$http', '$q'];
    function AllProgress($http, $q) {
      return $q(function(resolve, reject) {
        $http.get('api/admin/progress/all', {})
          .success(function(response){
            resolve(response);
          });
      });
    }

  }
})();