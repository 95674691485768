(function() {

  'use strict';

// Progress service used to communicate Events REST endpoints
  angular
    .module('adviser')
    .factory('ProgressData', ProgressData);

	function ProgressData() {
		var progress = {
      current: {}
    };

    return progress;
	}
})();