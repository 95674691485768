(function() {
  'use strict';

  angular
    .module('adviser')
    .factory('YouTubeAPI', YouTubeAPI);

  YouTubeAPI.$inject = ['_', '$q', '$window', '$interval'];
  function YouTubeAPI(_, $q, $window, $interval) {

    var exports = {
      YT: null,
      status: null,
      players: {},
      load: load,
      events: {},
      playSegment: playSegment
    };

    return exports;

    function load() {
      return $q(function(resolve, reject) {

        if (exports.YT) {
          exports.status = 'ready';
          resolve(exports.YT);
        } else {
          exports.YT = exports.status = 'loading';

          var tag = document.createElement('script');

          tag.src = 'https://www.youtube.com/iframe_api';
          var firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

          $window.onYouTubeIframeAPIReady = function() {
            exports.YT = $window.YT;
            exports.status = 'ready';
            resolve(exports.YT);
          };
        }
        
      });
    }

    function playSegment(player, start, end) {
      var pl = (typeof player === 'string') ? exports.players[player] : player;
      return $q(function(resolve, reject) {
        if (!pl) {
          reject({message: 'Player not found/ready.'});
        } else {
          
          // pl.addCueRange(start, end);
          exports.stopAt = end;
          exports.events.onStateChange = function(evt) {
            if (evt.data === 1) {
              if (exports.progress) {
                $interval.cancel(exports.progress);
              }

              exports.progress = $interval(function() {
                if (pl.getPlayerState() === 1 && pl.getCurrentTime() >= end) {
                  pl.pauseVideo();
                  $interval.cancel(exports.progress);
                  exports.events.onStateChange = null;
                }
              }, 200);
            } else {
              if (exports.progress) {
                $interval.cancel(exports.progress);
              }
            }
          }

          pl.seekTo(start);
          pl.playVideo();
          resolve(player);
          // player.cueVideoById()
        }
      });
    }

  }
})();