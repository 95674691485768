(function () {
  'use strict';
  angular
    .module('app.core')
    .constant('constants', {
      'name': 'Genomics Advisor',
      'cdn': 'http://205.186.156.50/cdn',
      'learnVid': 'w05tphqgnJo'
    })
    .constant('_', window._ );

})();
