(function() {

  'use strict';

//Users service used to communicate Events REST endpoints
  angular
    .module('users')
    .factory('Users', Users);

  Users.$inject = ['$resource'];

	function Users($resource) {
		return $resource('api/users/:userId', { 
      userId: '@userId'
		}, {
			update: {
				method: 'PUT'
			}
		});
	}
})();