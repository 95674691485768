(function() {

  'use strict';

  angular
    .module('adviser')
    .directive('categorySummary', categorySummary);

  function categorySummary() {

    var directive = {
      restrict: 'AE',
      scope: {
        user: '='
      },
      // controller: SummaryController,
      controllerAs: 'vm',
      bindToController: true
    }

    return directive;
  }

  // SummaryController.$inject = ['Answers'];
  // function SummaryController(Answers) {
  //   var vm = this;

  //   Answers.query({userkey: vm.user.userkey}, function(response) {
  //     var keys = ['SURENU', 'SURCLR', 'SURSUP', 'SURBST'];

  //     var answers = _.filter(response, function(ans) {
  //       return !!~_.indexOf(keys, ans.variable) && ans.value === 'Yes';
  //     });

  //     vm.answers = _.indexBy(answers, 'variable');
  //   });
  // }
})();