/**
 *
 * Progress Gauge
 *
 * Creates the "out of" number for the progress page.
 *
 */

(function() {
  'use strict';

  angular
    .module('adviser')
    .directive('exploreGraphBar', exploreGraphBar);

  exploreGraphBar.$inject = ['$timeout'];
  function exploreGraphBar($timeout) {
    var directive = {
      restrict: 'EA',
      require: '^exploreGraph',
      template: [
        '<span class="exploregraph-bar-index" data-ng-bind="vm.idx + 1"></span>',
        '<span class="exploregraph-bar-percent">{{vm.value}}</span>',
      ].join(''),
      scope: {},
      controller: ControllerBar,
      controllerAs: 'vm',
      bindToController: true,
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, elem, attr, ctrl) {

      scope.$watch(function(scope) {
        return ctrl.results.length;
      }, function(newVal) {
        if (newVal) {
          scope.vm.idx = +attr.index;
          scope.vm.result = ctrl.results[scope.vm.idx];

          scope.vm.value = 'Somewhat Important';

          if (scope.vm.result.percent > 66.666666) {
            scope.vm.value = 'Very Important';
          } else if (scope.vm.result.percent < 33.33333) {
            scope.vm.value = 'Not Important';
          }

          $timeout(function() {
            elem.css('width', scope.vm.result.percent + '%');
            elem.addClass('mod-extended');
          }, 100);
        }
      });
    }
  }

  function ControllerBar() {}

})();