(function() {
  'use strict';

  angular
    .module('adviser')
    .directive('youTube', youTube);

  youTube.$inject = ['YouTubeAPI'];
  function youTube(API) {
    var directive = {
      restrict: 'EA',
      // template: '<iframe width="620" height="315" data-ng-src="{{vm.source}}" frameborder="0" allowfullscreen></iframe>',
      // template: '<div id="learn-video"></div>',
      scope: {
        youTube: '@'
      },
      controller: YouTubeController,
      controllerAs: 'vm',
      bindToController: true,
      link: linkFunc

    };

    return directive;

    function linkFunc(scope, elem, attr, ctrl) {
      ctrl.elemId = elem.attr('id');

      API.load().then(function(response) {
        return ctrl.createPlayer(response, ctrl.elemId);
      }).then(function(player) {
        ctrl.player = API.players[ctrl.elemId] = player;
        ctrl.playerReady = true;
      });
    }
  }

  YouTubeController.$inject = ['$q', 'YouTubeAPI'];
  function YouTubeController($q, API) {
    var vm = this;

    vm.createPlayer = createPlayer;

    function createPlayer(YT, id) {
      return $q(function(resolve, reject) {
        var player = new YT.Player(id, {
          height: '562',
          width: '854',
          videoId: vm.youTube,
          playerVars: {
            showinfo: false,
            rel: false
          },
          events: {
            onReady: function() {
              resolve(player);
            },
            onStateChange: onPlayProgress
          }
        });
      });
    }

    function onPlayProgress(evt) {  
      if (typeof API.events.onStateChange === 'function') {
        API.events.onStateChange(evt);
      }
    }
    
  }
})();