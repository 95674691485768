/*

  Configure the Adviser routes

*/
(function () {
  angular
    .module('users')
    .config(Router);

  function Router($urlRouterProvider, $stateProvider){

    $stateProvider
      .state('auth', {
        url: '/auth',
        abstract: true,
        template: '<data-ui-view/>'
      })
      .state('auth.login', {
        url: '/login',
        templateUrl: 'modules/users/views/user-login.html',
        controller: 'UserAuthController',
        controllerAs: 'vm'
      });

  }
})();