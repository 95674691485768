(function() {

  'use strict';

  angular
    .module('admin')
    .controller('UserAuthController', UserAuthController);

  UserAuthController.$inject = ['$scope', '$http', '$state'];
  function UserAuthController($scope, $http, $state) {
    var vm = this;

    vm.login = login;
    
    activate();

    function activate() {
      $scope.globals.bodyClass = 'mod-dark';
    }
    
    function login() {

      vm.error = null;
      var creds = {
        userkey: vm.userkey,
        password: vm.password
      };

      $http.post('api/users/login', creds).success(function(response){
        $state.go('adviser.progress');
      }).error(function(response) {
        vm.error = response.error.message;
      });
    }
  }
})();