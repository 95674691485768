(function() {

  'use strict';

  angular
    .module('admin')
    .controller('AdminProgressController', AdminProgressController);

  AdminProgressController.$inject = ['_', '$http', '$state', 'auth', 'progressResolve'];
  function AdminProgressController(_, $http, $state, auth, progressResolve) {

    var vm = this;

    vm.$state = $state;
    vm.auth = auth;

    // vm.getResults = getResults;
    vm.activate = activate;
    vm.progress = null;

    vm.activate();

    function activate() {
      if ($state.current.name === 'admin.progress.all') {
        vm.progressTabTitle = 'All';
        vm.progress = progressResolve;
      } else if ($state.current.name === 'admin.progress.active') {
        vm.progressTabTitle = 'Active';
        vm.progress = _.filter(progressResolve, function(obj) {
          return !obj.dateComplete;
        });
      } else if ($state.current.name === 'admin.progress.complete') {
        vm.progressTabTitle = 'Complete';
        vm.progress = _.filter(progressResolve, function(obj) {
          return obj.dateComplete;
        });
      }
    }

  }
})();