(function() {

  'use strict';

  angular
    .module('adviser')
    .controller('ProgressController', ProgressController);

  ProgressController.$inject = ['$scope', '$state', 'userResolve', 'progressResolve'];
  function ProgressController($scope, $state, userResolve, progressResolve) {

    var vm = this;
    vm.waiting = true;
    vm.progress = progressResolve;
    vm.user = userResolve;

    vm.nextStep = nextStep;
    vm.reflectenter = reflectenter;

    activate();

    function activate() {
      $scope.globals.bodyClass = null;
      vm.initial = progressResolve.currentStep === 'learn' && +(progressResolve.currentView) === 0;

      if (vm.user.role === 'control') {
        vm.nextStep();
      }
    }

    function nextStep() {
      var params = {
        step: progressResolve.currentStep,
        view: progressResolve.currentView
      };

      $state.go('adviser.questions', params);
    }

    function reflectenter(){
      if (vm.reflectkey === 'okaygo') {
        vm.nextStep();
      } else {
        vm.error = 'Password Incorrect';
      }
    }

  }
})();