(function() {

  'use strict';

  angular
    .module('admin')
    .controller('AuthenticationController', AuthenticationController);

  AuthenticationController.$inject = ['$http', '$state', 'auth'];
  function AuthenticationController($http, $state, auth) {
    var vm = this;

    vm.auth = auth;
    vm.login = login;

    function login() {

      vm.error = null;

      var creds = {
        username: vm.username,
        password: vm.password
      };

      $http.post('api/admin/login', creds).success(function(response){
        $state.go('admin.home');
      }).error(function(response) {
        vm.error = response.error.message;
      });
    }
  }
})();