(function() {

  'use strict';

//Users service used to communicate Events REST endpoints
  angular
    .module('adviser')
    .factory('Questions', Questions);

  Questions.$inject = ['$http', '$q', '_'];

	function Questions($http, $q, _) {
		var questionData = {};
    
    var questionCategories = [
      {id: 'welcomed', name: 'Welcomed'}, 
      {id: 'learn', name: 'Learn'},
      {id: 'explore', name: 'Explore'},
      {id: 'prepare', name: 'Prepare'},
      {id: 'decide', name: 'Decide'},
      {id: 'reflect', name: 'Reflect'},
      {id: 'redecide', name: 'Decide'},
      {id: 'rereflect', name: 'Reflect'}
    ];

    var service = {
      load: load,
      categories: questionCategories
    };

    return service;

    function load(step, template) {
      return $q(function(resolve, reject) {
        if (questionData[step]) {
          resolve(questionData[step]);
        } else {
          $http.get('modules/adviser/data/' + (template || step) + '.json').success(function(response) {
            var obj = _.findWhere(questionCategories, {id: step});
            obj.questionCount = response.length;
            questionData[step] = response;
            resolve(response);
          });
        }
      });
    }

	}
})();