(function () {
  'use strict';

  angular
    .module('app.core')
    .constant('globals', {
      'debug': false
    });

})();
