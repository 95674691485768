(function() {

  'use strict';

// Progress service used to communicate Events REST endpoints
  angular
    .module('adviser')
    .factory('Progress', Progress);

  Progress.$inject = ['_', '$resource', 'Questions'];

	function Progress(_, $resource, Questions) {
		return $resource('api/adviser/progress/:userkey/:complete', { 
      userKey: '@userkey',
      complete: '@complete'
		}, {
      get: {
        method: 'GET',
        transformResponse: function(data) {
          var prog = angular.fromJson(data);
          if (prog) {
            prog.stepIndex = _.findIndex(Questions.categories, {id: prog.currentStep});
          } else {
            prog = {};
          }
          return prog;
        }
      },
			update: {
				method: 'PUT'
			}
		});
	}
})();