(function() {

  'use strict';

// Answers service used to communicate Events REST endpoints
  angular
    .module('adviser')
    .factory('Answers', Answers);

  Answers.$inject = ['$resource'];

	function Answers($resource) {
		return $resource('api/adviser/answers/:userkey', { 
      userKey: '@userkey'
		}, {
			update: {
				method: 'PUT'
			}
		});
	}
})();