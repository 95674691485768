/*

  Google Analytics

    ga.pageview();

*/
;(function () {

  angular
    .module('app.core')
    .factory('ga', GoogleAnalytics)

  function GoogleAnalytics($q, $http, dataStore, AudioAPI, BackgroundAPI){

    (function(i,s,o,g,r,a,m){
      i['GoogleAnalyticsObject'] = r;
      i[r] = i[r]||function(){
        (i[r].q = i[r].q||[]).push(arguments)
      };
      i[r].l = 1*new Date();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a,m);
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    window.ga('create', 'UA-',  {name:'a'});
    // optionally add more tracking codes by specifying names for each...

    return {
      pageview: pageview
    }

    function pageview(){
      window.ga('a.send', 'pageview');
      // ...and referencing their names when you make function calls
    }

  }

})();