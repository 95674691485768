(function() {

  'use strict';

  angular
    .module('adviser')
    .controller('WelcomeController', WelcomeController);

  WelcomeController.$inject = ['$scope', '$state', 'Progress', 'configResolve', 'userResolve', 'progressResolve'];
  function WelcomeController($scope, $state, Progress, configResolve, userResolve, progressResolve) {

    var vm = this;

    vm.user = userResolve;
    vm.config = configResolve;
    vm.nextStep = nextStep;

    activate();
    function activate() {
      $scope.globals.bodyClass = 'mod-dark';

      if (vm.user.role === 'control') {
        vm.nextStep();
      }
    }

    function nextStep() {
      vm.waiting = true;

      var progress = new Progress({
        currentStep: vm.user.role === 'control' ? 'reflect' : 'learn',
        currentView: 0
      });

      progress.$save({userkey: vm.user.userkey}, function(response) {
        $state.go('adviser.progress');
        vm.waiting = false;
      }, function(error) {
        vm.waiting = false;
        vm.error = error.data.error.message;
      });
    }

  }
})();