/**
 * adviser-header.client.controller.js
 *
 * Handles all the functionality for header and navigation
 * of the adviser secton.
 */

(function() {

  'use strict';

  angular
    .module('adviser')
    .controller('AdviserHeaderController', AdviserHeaderController);

  AdviserHeaderController.$inject = ['$scope', '$state', '$http', 'Questions', 'ProgressData', '_'];
  function AdviserHeaderController($scope, $state, $http, Questions, ProgressData, _) {
    var vm = this;

    vm.waiting = true;
    vm.$state = $state;
    vm.prog = ProgressData;
    vm.categories = Questions.categories;

    activate();

    function activate() {
      $http.get('api/users/authenticate', {})
        .success(function(response){
          vm.user = response;
        })
        .error(function(err) {
          $state.go('auth.login');
        });
    }

    // Collapsing the menu after navigation
    $scope.$on('$stateChangeSuccess', function() {
      vm.isCollapsed = false;
    });

  }
})();