/**
 *
 * Progress Header
 *
 * Creates the standard header on question pages to show where
 * the user is in the experience. It only counts the nodes that
 * have questions in them.
 *
 */

(function() {
  'use strict';

  angular
    .module('adviser')
    .directive('progressHeader', progressHeader);

  function progressHeader() {
    var directive = {
      restrict: 'EA',
      template: [
        '<div class="progheader">',
          '<div class="clearfix">',
            '<div class="progheader-step pull-left" ><svg class="progheader-step-logo"><use ng-href="#icon-{{vm.category.id}}" xlink:href=""></svg><span data-ng-bind="vm.catName"></span></div>',
            '<div class="progheader-nums pull-right">{{vm.viewIndex}}/{{vm.totalLength}}</div>',
          '</div>',
          '<div class="progheader-bar"><div class="progheader-bar-inner" style="width:{{vm.barWidth}}%"></div></div>',
        '</div>',
      ].join(''),
      scope: {},
      controller: ProgheaderController,
      controllerAs: 'vm',
      bindToController: true,
      link: linkFunc
    };

    return directive;

    function linkFunc(scope, elem, atts) {

    }
  }

  ProgheaderController.$inject = ['_', 'Questions', '$stateParams'];
  function ProgheaderController(_, Questions, $stateParams) {
    var vm = this;

    vm.category = _.findWhere(Questions.categories, {id: $stateParams.step});
    vm.catName = vm.category.name;

    Questions.load($stateParams.step).then(function(response) {
      if (response) {

        var qs = _.filter(response, function(qstns) {
          return qstns.questions;
        });

        if (!qs.length) {
          qs = _.rest(response);
        }

        // var dif = response.length - qs.length;

        vm.totalLength = (response.length - 1); // - dif;
        vm.viewIndex = +$stateParams.view; // + 1 - dif;
        vm.barWidth = (vm.viewIndex/vm.totalLength)*100;
      }
    });
  }

})();