(function() {

  'use strict';

  angular
    .module('admin')
    .controller('AdminController', AdminController);

  AdminController.$inject = ['$http', '$state', 'Users', 'auth'];
  function AdminController($http, $state, Users, auth) {

    var vm = this;
    vm.auth = auth;
    vm.userKey = null;

    vm.addUser = addUser;
    vm.userResults = userResults;

    function addUser() {

      vm.error = vm.success = null;

      var role = vm.role ? 'control' : 'user';

      $http.post('api/admin/user/create', {userkey: vm.userKey, password: vm.userPassword, role: role}).success(function(response){

        if (response.created) {
          vm.success = 'New user created. Password: ' + response.password;
        } else {
          vm.success = 'User already exists. Password: ' + response.password;
        }
      }).error(function(response) {
        vm.error = response.error.message;
      });
    }

    function userResults(userKey) {
      vm.error = vm.success = null;

      if (!userKey) {
        vm.error = 'Please add a User Key.';
      } else {
        $state.go('admin.results.user', {userKey: userKey});
      }
    }
  }
})();