/**
 *
 * Progress Gauge
 *
 * Creates the "out of" number for the progress page.
 *
 */

(function() {
  'use strict';

  angular
    .module('adviser')
    .directive('progressGauge', progressGauge);

  function progressGauge() {
    var directive = {
      restirce: 'EA',
      template: [
        '<div data-ng-if="vm.completed">Completed</div>',
        '<div data-ng-if="vm.totalLength && !vm.completed">{{vm.progress.currentView}} / {{vm.totalLength}}</div>'
      ].join(''),
      scope: {
        progress: '=',
        progressGauge: '@'
      },
      controller: GaugeController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;
  }

  GaugeController.$inject = ['_', 'Questions'];
  function GaugeController(_, Questions) {
    var vm = this;
    vm.categories = Questions.categories;
    vm.progressStepIndex = _.findIndex(vm.categories, {id: vm.progress.currentStep});
    vm.gaugeStepIndex = _.findIndex(vm.categories, {id: vm.progressGauge});

    if (vm.progressStepIndex > vm.gaugeStepIndex) {
      vm.completed = true;
    }


    if (vm.progressStepIndex === vm.gaugeStepIndex) {
      vm.data = Questions.load(vm.progressGauge).then(function(response) {
        vm.totalLength = response.length - 1;

        /**
         * For the last step show completed if the user is on the last page.
         */
        if ((vm.progressStepIndex + 1) === vm.categories.length && +vm.progress.currentView === +vm.totalLength) {
          vm.completed = true;
        }
      });
    }


  }

})();