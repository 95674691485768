(function() {
  'use strict';

  angular
    .module('adviser')
    .filter('formattedList', formattedList);

  function formattedList() {

    return function(input) {
      return input.slice(0, -1).join(', ') + (input.length > 1 ? ' and ' : '') + input[input.length-1]
    }
    
  }
})();