/**
 * adviser.client.controller.js
 *
 * Handles all the functionality for the bulk of the quiz
 * including loading in questions, answering, navigating
 * and updating the progress.
 */

(function() {

  'use strict';

  angular
    .module('adviser')
    .controller('AdviserController', AdviserController);

  AdviserController.$inject = ['$scope', '$state', '$q', '$http', 'Progress', 'Questions', 'Answers', 'configResolve', 'userResolve', 'progressResolve', 'questionsResolve', '_'];
  function AdviserController($scope, $state, $q, $http, Progress, Questions, Answers, configResolve, userResolve, progressResolve, questionsResolve, _) {

    var vm = this;

    vm.step = $state.params.step;
    vm.questionTotal = questionsResolve.length - 1;
    vm.currentView = +($state.params.view);
    vm.progressView = +(progressResolve.currentView);

    vm.config = configResolve;
    vm.user = userResolve;
    vm.categories = Questions.categories;
    vm.stateStepIndex = _.findIndex(vm.categories, {id: $state.params.step});
    vm.questionData = questionsResolve[vm.currentView];
    vm.answerState = null;

    vm.checkNextProgress = checkNextProgress
    vm.checkAnswer = checkAnswer;

    vm.activate = activate;

    vm.setCheckValidation = setCheckValidation;
    vm.nextStep = nextStep;
    vm.back = back;
    vm.getAnswers = getAnswers;
    vm.updateAnswers = updateAnswers;
    vm.updateProgress = updateProgress;
    vm.toggleCheckbox = toggleCheckbox;
    vm.adviserComplete = adviserComplete;
    vm.fillRedecideAns = fillRedecideAns;
    vm.submitComments = submitComments;

    activate();

    function activate() {

      if (!validateProgress()) {
        $state.go('adviser.progress');
      }

      /**
       * Set the body class based on the data.
       */
      $scope.globals.bodyClass = vm.questionData.bodyclass || null;

      vm.getAnswers().then(function(response) {
        vm.answers = response;
        
        /**
         * Loop through and make sure there is an object in
         * answers for each current question.
         */
        _.each(vm.questionData.questions, function(qstn) {

          vm.answers[qstn.id] = vm.answers[qstn.id] || {};

          if (qstn.type === 'checkbox') {
            vm.answers[qstn.id].value = (vm.answers[qstn.id].value) ? vm.answers[qstn.id].value.split(',') : [];
          }

          if (qstn.a && vm.answers[qstn.id].value) {
            vm.answerState = vm.checkAnswer(vm.answers[qstn.id].value, qstn.a) ? 'correct' : 'incorrect';
          }
        });

        vm.questionTemplate = vm.questionData.template || 'modules/adviser/views/' + vm.step + '/' + vm.step + '-questions.html';
      });
    }

    /**
     * Controller Helper Functions
     */
    function checkNextProgress(step, view) {
      var stepIndex = _.findIndex(vm.categories, {id: step});

      if (view === 0) {
        return stepIndex === progressResolve.stepIndex + 1;
      } else {
        return stepIndex === progressResolve.stepIndex && view === vm.progressView + 1; 
      }
    }

    function checkAnswer(ans, key) {
      if (typeof ans === 'string') {
        return ans === key;
      } else {
        if (ans.length !== key.length) { return false; }
        else {
          return _.filter(ans, function(o) {
            return key.indexOf(o) === -1;
          }).length < 1;
        }
      }
    }

    /**
     * Methods
     */

    function setCheckValidation(id) {
      vm.questionForm.$setValidity(id, vm.answers[id].value.length > 0);
    }

    function validateProgress() {

      if (vm.stateStepIndex > progressResolve.stepIndex) {
        return false;
      }

      if (vm.stateStepIndex === progressResolve.stepIndex && vm.currentView > vm.progressView) {
        return false;
      }

      return true;
    }

    function getAnswers() {
      /**
       * Get answers if this isn't the latest view.
       */

      return $q(function(resolve, reject) {

        if (vm.stateStepIndex !== progressResolve.stepIndex || vm.currentView !== vm.progressView) {

          Answers.query({userkey: userResolve.userkey}, function(response) {
            if (response) {
              var answers = _.indexBy(response, 'variable');

              resolve(answers);

            } else {
              resolve({});
            }
          });
        } else {
          resolve({});
        }
      });
      
    }

    function updateProgress(step, view, answerStatus) {
      return $q(function(resolve, reject) {

        if (vm.checkNextProgress(step, view)) {
          var progress = new Progress({
            currentStep: step,
            currentView: view
          });

          if (step !== $state.params.step) {
            progress[$state.params.step + 'Complete'] = (new Date()).toISOString().substring(0, 19).replace('T', ' ');
          }

          progress.$update({userkey: vm.user.userkey}, function(response) {
            response.answerStatus = answerStatus;
            resolve(response);
          }, function(error) {
            reject(error);
          });
        } else {
          resolve({currentStep: step, currentView: view, answerStatus: answerStatus});
        }
      });
    }

    function updateAnswers() {
      return $q(function(resolve, reject) {

        var answerData = _.map(vm.questionData.questions, function(val, i) {
          var value = val.type === 'checkbox' ? vm.answers[val.id].value.join(',') : vm.answers[val.id].value;
          var obj = {
            id: vm.answers[val.id].id || null,
            adviserId: userResolve.userkey + '_' + val.id,
            variable: val.id,
            userkey: userResolve.userkey,
            value: value || '',
            category: $state.params.step,
            scale: vm.questionData.scale,
            bin: vm.questionData.bin,
            optional: val.optional || false
          };
          return obj;
        });

        if (!answerData.length) { return resolve({}); }
        if (vm.answerState) { return resolve({}); }

        var answers = new Answers(answerData);

        answers.$save({userkey: vm.user.userkey}, function(response) {
          resolve(response);
        }, function(response) {
          reject(response);
        });
      });
    }

    function nextStep(valid) {
      vm.waiting = true;

      var view = vm.currentView + 1;
      if (view > vm.questionTotal) { view = 0; }

      var step = view === 0 ? vm.categories[vm.stateStepIndex + 1].id : $state.params.step;

      vm.updateAnswers()
        .then(function(response) {
          if (vm.questionData.hasAnswer && response.status === 200) {
            _.each(vm.questionData.questions, function(qstn) {
              vm.answerState = checkAnswer(vm.answers[qstn.id].value, qstn.a) ? 'correct' : 'incorrect';
            });
          }
          
          return vm.updateProgress(step, view, response.status);
        })
        .then(function(response) {
          vm.waiting = false;

          if (!vm.questionData.hasAnswer || response.answerStatus !== 200) {
            $state.go('adviser.questions', {step: step, view: view});
          }
          
          
        }).catch(function(response) {
          vm.waiting = false;

          if (response.status === 403) {
            $state.go('auth.login');
          } else if (response.status === 406) {
            var error = angular.fromJson(response.data.error.message);
            vm.error = error.message;
            
          } else {
            vm.error = 'There was an error.';
          }
          
        });

    }

    function back() {
      vm.waiting = true;
      var params;
      if (vm.currentView - 1 < 0) {
        var prev = Questions.categories[vm.stateStepIndex - 1].id;
        
        Questions.load(prev).then(function(response) {
          params = {
            step: prev || 'learn',
            view: response.length - 1
          };

          vm.waiting = false;
          $state.go('adviser.questions', params);
        });

      } else {
        params = {
          step: $state.params.step,
          view: vm.currentView - 1
        };

        vm.waiting = false;
        $state.go('adviser.questions', params);
      }
    }

    function toggleCheckbox(value, id) {

      var idx = vm.answers[id].value.indexOf(value);

      if (idx > -1) {
        vm.answers[id].value.splice(idx, 1);
      } else {
        vm.answers[id].value.push(value);
      }

      vm.questionForm.$setValidity(id, vm.answers[id].value.length > 0);
      
    }

    function adviserComplete() {

      var progress;
      
      if (!progressResolve.dateComplete) {
        vm.success = vm.error = null
        vm.waiting = true;
        progress = new Progress({
          reflectComplete: (new Date()).toISOString().substring(0, 19).replace('T', ' '),
          dateComplete: (new Date()).toISOString().substring(0, 19).replace('T', ' '),
          currentStep: 'redecide',
          currentView: 0
        });

        progress.$update({userkey: vm.user.userkey, complete: 'complete'}, function(response) {
          vm.waiting = null;
          vm.success = true;
        }, function(error) {
          vm.error = error;
        });
      } else if (progressResolve.currentStep === 'rereflect' && !progressResolve.rereflectComplete) {

        vm.success = vm.error = null
        vm.waiting = true;

        progress = new Progress({
          rereflectComplete: (new Date()).toISOString().substring(0, 19).replace('T', ' '),
        });

        progress.$update({userkey: vm.user.userkey}, function(response) {
          vm.success = true;
        }, function(error) {
          vm.error = error;
        });
      } else {

        vm.success = true;
        
      }
    }

    function fillRedecideAns() {

      vm.waiting = true;

      Answers.query({userkey: userResolve.userkey}, function(response) {
        if (response) {
          var ans = _.filter(response, {category: 'decide'});
          var redecide = _.map(ans, function(val, key) {
            delete val.id;
            val.adviserId += '2';
            val.category = 'redecide';
            val.variable += '2';

            return val;

          });

          var answers = new Answers(redecide);

          answers.$save({userkey: vm.user.userkey}, function(response) {
            var progress = new Progress({
              currentStep: 'rereflect',
              currentView: 1,
              redecideComplete: (new Date()).toISOString().substring(0, 19).replace('T', ' ')
            });

            progress.$update({userkey: vm.user.userkey}, function(response) {
              vm.waiting = false;
              $state.go('adviser.questions', {step: 'rereflect', view: 1});
            }, function(error) {
              vm.waiting = false;
              vm.error = error;
            });
          }, function(response) {
            vm.waiting = false;
            vm.error = response;
          });

        } else {
          // No answers. Return to progress page.
          $state.go('adviser.progress');
        }
      });
    }

    function submitComments(comments) {
      vm.feedbackSuccess = vm.feedbackError = null;
      vm.feedbackWaiting = 'Sending. Please wait a moment...';

      $http.post('api/adviser/comments', {comments: comments}).success(function(response) {
        vm.feedbackWaiting = null;
        vm.feedbackSuccess = response.message;
        vm.feedback = '';
      }).error(function(err) {
        vm.feedbackWaiting = null;
        vm.feedbackError = err.error.message;
      });
    }

  }
})();